// Import necessary modules and hooks from React
import React, { useEffect, useRef, useState } from 'react';

// Routing and navigation
import { useParams } from "react-router-dom";

// Import custom Panel component
import Panel from '../../panel';

// Import a custom NavLink component for building details
import BuildingDetailsNavlink from "../common/BuildingDetailsNavlink";

// Utility libraries
import moment from "moment/moment";  // Date handling
import constants from "../../../../../component/constants";  // Application-wide constants

// State management using Redux
import { useSelector } from "react-redux";

// Error handling and data export components
import BuildingErrorCard from "../common/BuildingErrorCard";

// Import the custom components used for rendering charts and functionalities
import BuildingChartsExport from "../common/BuildingChartsExport";
import BuildingInquiry from "../common/BuildingInquiry";
import ExpiringLeases from "./charts/ExpiringLeases";
import Prospects from "./charts/Prospects";
import ShowcaseTable from "./components/ShowcaseTable";

const BuildingOccupancyDetails = ( props ) => {
    // Extract buildingId from URL parameters
    const { buildingId } = useParams();
    // Access application URL from constants
    const { APP_URL } = constants;

    // Get authentication data from Redux store
    const { authData, helper } = useSelector((state) => ({
        authData: state.dataPack.authData,
        helper: state.dataPack.helper
    }));

    // Get user info from authData
    const user = authData?.user;

    const [buildingError, setBuildingError] = useState(null);
    const [isBuildingLoading, setIsBuildingLoading] = useState(false);
    const [building, setBuilding] = useState(null);

    // States for handling building metrics data
    const [metricsError, setMetricsError] = useState(null);
    const [isMetricsLoading, setIsMetricsLoading] = useState(false);
    const [buildingMetrics, setBuildingMetrics] = useState([]);

    // Metric date range states
    const [metricsStartDate, setMetricsStartDate] = useState(moment().subtract(6, 'months'));
    const [metricsEndDate, setMetricsEndDate] = useState(moment());

    // Ref to manage fetch request aborts
    const abortControllerForBuilding = useRef(null);
    const abortControllerForMetrics = useRef(null);

    // Create a ref to reference the HTML content you want to convert to PDF
    const chartsReference = useRef();

    useEffect(() => {
        document.title = 'Residential Occupancy View | RentPerks';

        const fetchBuilding = async () => {
            // Abort any previous fetch request
            abortControllerForBuilding.current?.abort();
            abortControllerForBuilding.current = new AbortController();

            // Set loading state
            setIsBuildingLoading(true);

            // Construct the URL for fetching building data with query parameters
            const buildingEndpoint = APP_URL + `buildings/${buildingId}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingEndpoint, {
                    signal: abortControllerForBuilding.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    setBuilding(result.data);  // Set the building data
                } else {
                    // If the response is not OK, handle error case
                    setBuildingError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Aborted');
                    return;
                }

                // Set error state if fetch fails
                setBuildingError(e);
            } finally {
                // Reset loading state
                setIsBuildingLoading(false);
            }
        };

        fetchBuilding();
    }, []);

    useEffect(() => {
        if (!metricsStartDate || !metricsEndDate) {
            return;
        }

        const fetchBuildingMetrics = async () => {
            // Abort any previous fetch request
            abortControllerForMetrics.current?.abort();
            abortControllerForMetrics.current = new AbortController();

            // Set loading state
            setIsMetricsLoading(true);

            // Construct the URL for fetching building data with query parameters
            const formattedMetricsStartDate = moment(metricsStartDate).format('YYYY-MM-DD');
            const formattedMetricsEndDate = moment(metricsEndDate).format('YYYY-MM-DD');
            const buildingMetricsEndpoint = APP_URL + `buildings/${buildingId}/metrics?scope=occupancy&startDate=${formattedMetricsStartDate}&endDate=${formattedMetricsEndDate}`;

            try {
                // Fetch buildings data from API
                const response = await fetch(buildingMetricsEndpoint, {
                    signal: abortControllerForMetrics.current?.signal,
                });
                const result = await response.json();

                // Check if the API response was successful
                if (response.ok && result.status === 'success') {
                    setBuildingMetrics(result.data.occupancy);  // Set the building metrics data
                } else {
                    // If the response is not OK, handle error case
                    setMetricsError(result.message);
                }
            } catch (e) {
                if (e.name === 'AbortError') {
                    console.log('Fetch Building Metrics Aborted');
                    return;
                }

                // Set error state if fetch fails
                setMetricsError(e);
            } finally {
                // Reset loading state
                setIsMetricsLoading(false);
            }
        };

        // Call the fetch function
        fetchBuildingMetrics();
    }, [metricsStartDate, metricsEndDate]);

    return (
        <div className="row justify-content-center section_height">
            <Panel {...props} />

            <div className="col-lg-10 col-md-10 col-sm-12 col-xl-10">
                {/* Check if there is an error. */}
                {buildingError || metricsError ? (
                    <BuildingErrorCard/>
                ) : (
                    <>
                        <div className="row mb-10">
                            <div className="col-md-6">
                                <BuildingDetailsNavlink buildingId={buildingId}/>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-4">
                                        <BuildingChartsExport
                                            building={building}
                                            reference={chartsReference}
                                            config={{
                                                filename: 'building-occupancy-charts',
                                                title: 'Occupancy Charts',
                                                format: 'a4',
                                                orientation: 'landscape'
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <BuildingInquiry ownerId={user.customer_id}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isMetricsLoading ? (
                            // Show loader while data is loading
                            <div className="loader"></div>
                        ) : (
                            <>
                                <div className="row" ref={chartsReference}>
                                    <div className="col-md-6">
                                        <ExpiringLeases data={buildingMetrics.expiring_leases}/>
                                    </div>
                                    <div className="col-md-6">
                                        <Prospects data={buildingMetrics.prospects}/>
                                    </div>
                                </div>

                                {buildingMetrics.showcase && (
                                    <div className="mt-6">
                                        <ShowcaseTable data={buildingMetrics.showcase}/>
                                    </div>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export {BuildingOccupancyDetails}
