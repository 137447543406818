import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Chart from 'react-apexcharts';

const ExpiringLeases = ({ data }) => {
    // Check if data is defined
    if (!data) {
        return (
            <Card className="black-card py-3">
                <Card.Body
                    className="d-flex flex-column font-weight-light justify-content-center text-center text-white p-0">
                    <h3 className="text-lg font-weight-light text-uppercase tracking-wide mb-1">Expiring Leases</h3>
                    <div className="px-2 block text-sm font-weight-light leading-snug tracking-wide">Chart generation failed: Insufficient Data</div>
                </Card.Body>
            </Card>
        );
    }

    const generateChartOptions = () => {
        // Define the aging categories for the Y-axis
        const categories = ['7', '30', '60', '90'];
        const chartData = [
            data['1_7_days'],
            data['1_30_days'],
            data['1_60_days'],
            data['1_90_days'],
        ];

        return {
            series: [
                {
                    name: 'Expiring Leases',
                    data: chartData,
                    color: '#B38840',
                },
            ],
            options: {
                chart: {
                    type: 'bar',
                    toolbar: {
                        show: false,
                    },
                    events: {
                        mounted: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#expiring-leases-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        },
                        updated: function() {
                            // Remove all <title> elements inside the ApexCharts SVG
                            const svgTitles = document.querySelectorAll("#expiring-leases-chart .apexcharts-svg title");
                            svgTitles.forEach(title => title.remove());
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded',
                        dataLabels: {
                            enabled: false,
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                },
                title: {
                    text: 'EXPIRING LEASES',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: 'light',
                        color: '#FFFFFF',
                        fontFamily: 'Inter, sans-serif',
                    },
                },
                xaxis: {
                    categories: categories,
                    title: {
                        text: 'DAYS',
                        style: {
                            fontSize: '12px',
                            fontWeight: 'light',
                            color: '#FFFFFF',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '13px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    axisTicks: {
                        show: true,
                        color: '#FFFFFF',
                        height: 8,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                    },
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value;
                        },
                        style: {
                            colors: '#FFFFFF',
                            fontSize: '14px',
                            fontFamily: 'Inter, sans-serif',
                        },
                    },
                    title: {
                        text: undefined,
                    },
                    axisBorder: {
                        show: true,
                        color: '#FFFFFF',
                        offsetX: -1,
                        offsetY: 0
                    },
                    stepSize: 1,
                },
                grid: {
                    show: false, // Disable both horizontal and vertical grid lines
                },
                fill: {
                    opacity: 1,
                    colors: ['#A67C00', '#FFFFFF'],
                },
                tooltip: {
                    theme: 'dark',
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Arial',
                        color: '#FFFFFF',
                    },
                    fillSeriesColor: false,
                    marker: {
                        show: true,
                    },
                    x: {
                        show: true,
                        formatter: function (value) {
                            return value + ' DAYS';
                        },
                    },
                    y: {
                        formatter: function (value) {
                            return value;
                        },
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'center',
                    labels: {
                        colors: ['#FFFFFF', '#FFFFFF'],
                    },
                    fontSize: '14px',
                    fontFamily: 'Inter, sans-serif',
                }
            },
        }
    };

    // Get the chart options for this building
    const chartOptions = generateChartOptions();

    return (
        <Card className="black-card">
            <Card.Body className="text-white px-2 pt-3 pb-0">
                <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height="330" className="d-block m-auto" id="expiring-leases-chart" />
            </Card.Body>
        </Card>
    );
}

// Define the prop types for the component
ExpiringLeases.propTypes = {
    data: PropTypes.shape({
        '1_7_days': PropTypes.number.isRequired,
        '1_30_days': PropTypes.number.isRequired,
        '1_60_days': PropTypes.number.isRequired,
        '1_90_days': PropTypes.number.isRequired,
    }),
};

export default ExpiringLeases
